<template>
  <div class="w-full px-2">
    <div class="flex flex-wrap-mx-2">
      <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
        <div class="vx-row bg-white w-full">
          <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 p-2">
            <!--      <iframe
                   class="w-full"
                   height="400"
                   id="gmap_canvas"
                   src="https://maps.google.com/maps?q=Space+Needle,Seattle+WA&t=&z=13&ie=UTF8&iwloc=&output=embed"
                   frameborder="0"
                   scrolling="no"
                   marginheight="0"
                   marginwidth="0"
                 ></iframe> -->
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7228.6030981521535!2d55.1326092!3d25.0577666!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f134ae10f0c45%3A0x5a311cb63c3abf1e!2sJewellery%20%26%20Gemplex%20Building!5e0!3m2!1sen!2seg!4v1612169056355!5m2!1sen!2seg"
              frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" width="100%"
              height="450"></iframe>
          </div>
          <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 p-2">
            <div class="vx-row ml-2 w-full mb-2">
              <span class="text-sm text-grey-light">
                <feather-icon
                  icon="MapPinIcon"
                  svgClasses="h-4 w-4"
                  class="ml-1 text-primary"/>
                {{ Settings.address }}
              </span>
            </div>
            <div class="vx-row ml-2 w-full mb-2">
              <span class="text-sm text-grey-light">
                <feather-icon
                  icon="MailIcon"
                  svgClasses="h-4 w-4"
                  class="ml-1 text-primary"
                />

               {{ Settings.email }}
              </span>
            </div>
            <div class="vx-row ml-2 w-full mb-2">
              <span class="text-sm text-grey-light">
                <feather-icon
                  icon="PhoneIcon"
                  svgClasses="h-4 w-4"
                  class="ml-1 text-primary"
                />

                {{ Settings.phone }}
              </span>
            </div>

            <div class="mt-5 md:ml-4 lg:ml-4 xl:ml-4">
              <label class="text-md">your name</label>
              <vs-input
                :name="$t('User Name')"
                v-validate="'required'"
                placeholder="Enter Full Name"
                v-model="Model.name"
                class="w-full mt-2"
              />
              <span class="text-danger text-md">{{
                  errors.first($t("User Name"))
                }}</span>
            </div>
            <div class="mt-5 md:ml-4 lg:ml-4 xl:ml-4">
              <label class="text-md">Subject</label>
              <vs-input
                :name="$t('Subject')"
                v-validate="'required'"
                placeholder="Enter Subject"
                v-model="Model.subject"
                @input="delete responseError.subject"
                class="w-full mt-2"
              />
              <span class="text-danger text-md">{{
                  errors.first($t("Subject"))
                }}</span>
              <span class="text-danger" v-if="responseError.hasOwnProperty('subject')">{{responseError.subject[0]}}</span>

            </div>
            <div class="mt-5 md:ml-4 lg:ml-4 xl:ml-4">
              <label class="text-md">your email</label>
              <vs-input
                v-validate="'required|email'"
                :name="$t('Email')"
                v-model="Model.email"
                placeholder="Enter Email"
                class="w-full "
              />
              <span class="text-danger text-md">{{
                  errors.first($t("UserName"))
                }}</span>
            </div>
            <div class="mt-5 md:ml-4 lg:ml-4 xl:ml-4">
              <label class="text-md">your message</label>
              <vs-textarea
                v-model="Model.message"
                rows="4" @input="delete responseError.message"
                class="w-full mt-2"
              />
              <span class="text-danger text-md">{{
                  errors.first($t("NationalID"))
                }}</span>
              <span class="text-danger" v-if="responseError.hasOwnProperty('message')">{{responseError.message[0]}}</span>
            </div>
            <vs-button
              class="mb-4 rounded-full md:w-auto float-right m-2"
              :disabled="!validateForm"
              @click="ContactUS"
              color="primary"
            >Submit Message
            </vs-button>
          </div>
        </div>

        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-primary text-3xl font-bold">
              Are you interested in participating?
            </span>
          </div>
        </div>
        <div class="vx-row bg-white mb-4 w-full">
          <div class="w-full p-2">
            <span class="text-grey-light text-lg">
WRO Virtual Robotics competitions are organized in many countries around the world. WRO National Organizers are responsible for the competition in their country. Take a look here if competitions are available in your country.
            </span>
          </div>
        </div>
        <div class="vx-row bg-white mt-8 w-full">
          <div class="w-full p-2">
            <vs-button
              class="mb-4 rounded-full md:w-auto float-left m-2"
              @click="$router.push({ name: 'MemberCountries' })"
              color="primary"
            > Countries
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Model: {},
      responseError: {}
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.Model.email != "" &&
        this.Model.subject != "" &&
        this.Model.name != "" &&
        this.Model.message != null &&
        this.Model.message != ""
      );
    },
    Settings() {
      return this.$store.state.auth.Settings;
    }
  },
  methods: {
    ContactUS() {
      if (!this.validateForm) {
        window.showFillData();
        return;
      }

      this.$vs.loading();

      this.$store
        .dispatch("auth/addcontactus", this.Model)
        .then(res => {
          // debugger;
          if (res.status == 200) {
            // debugger;
            this.$vs.loading.close();
            window.showSuccess();
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          this.responseError = err.response.data.message
          window.showError();
        });
    }
  }
};
</script>
